import {filter, takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from './services/user.service';
import {AuthGuardService} from "./services/auth-guard.service";
import {Subject} from 'rxjs';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  access_token: string = null;
  newUrl: string = null;
  url: string = null;
  orgaCheckCompleted = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private authGuardService: AuthGuardService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(async (event: any) => {
      this.url = event.url;
      // If url has orga_id it means we want to change organisation. Let's block router-outlet until this is finished
      const orgaIdParam = this.getQueryVariable('orga_id');
      if (orgaIdParam) {
        this.accessTokenHandler();
        await this.changeOrganisation(parseInt(orgaIdParam, 10));
        this.orgaCheckCompleted = true;
      } else {
        this.accessTokenHandler();
        this.orgaCheckCompleted = true;
      }
    });
  }
  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }
  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      console.log('checkAndSetActiveAccount', accounts);
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    if (this.msalService.instance.getActiveAccount() !== null) {
      this.userService.setMsalUserFetched(true);
    }
  }
  accessTokenHandler() {
    if (this.access_token === null) { this.access_token = this.getQueryVariable('access_token'); }
    if (this.access_token) {
      this.newUrl = this.removeURLParameter(this.url, 'access_token');
      this.accessTokenLogin(this.access_token);
      this.access_token = '';
    }
  }

  async changeOrganisation(orgaId) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.id) {
      await this.userService.updateUser(user.id, {organisation_id: orgaId});
      const meResult = await this.userService.getMe();
      localStorage.setItem('user', JSON.stringify(meResult));
    }
  }

  accessTokenLogin(token) {
    this.http.get(environment.apiUrl + 'auth/me', {}).subscribe(data => this.loggedIn(data, token), err => this.handleError(err));
  }

  loggedIn(res, token) {
    localStorage.setItem('impact-token', token);
    localStorage.setItem('user', JSON.stringify(res.user));
    this.router.navigateByUrl(this.newUrl);
  }

  handleError(res) {}

  getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  removeURLParameter(url, parameter) {
    // prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const pars = urlparts[1].split(/[&;]/g);

      // reverse iteration as may be destructive
      for (let i = pars.length; i-- > 0; ) {
        // idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      return url;
    } else {
      return url;
    }
  }
}
